@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

body {
  margin: 0 !important;
  padding: 0 !important;
  color: white !important;
  background-color: #0e0e10;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #a3a3a3;
  background: #1f1f23;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #a3a3a3;
  border-radius: 10px;
}

.react-player {
  /* position: absolute; */
  top: 0;
  left: 0;
}

a {
  color: white !important;
  text-decoration: none;
  font-weight: bolder;
}
