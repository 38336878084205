.custom-shape-divider-bottom-1669072772 {
  position: relative;
  bottom: 0 !important;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  margin-top: 100px !important;
}

.custom-shape-divider-bottom-1669072772 svg {
  position: relative;
  display: block;
  width: calc(118% + 1.3px);
  height: 158px;
  transform: rotateY(180deg);
  bottom: 0 !important;
  margin-bottom: 100px !important;
}

.custom-shape-divider-bottom-1669072772 .shape-fill {
  fill: #90038d;
}
