.custom-shape-divider-bottom-1669060695 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-1669060695 svg {
  position: relative;
  display: block;
  width: calc(110% + 1.3px);
  height: 79px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1669060695 .shape-fill {
  fill: #0e0e10;
}

h1 {
  font-size: 40pt;
  color: #9c1db8;
}

h2 {
  font-size: 20pt;
  color: grey;
}